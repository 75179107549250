/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202409252235-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * The status for each connection that provides data to this datasource. Provided only when the required level of detail is 'Complete'
 */
export interface ConnectionStatusOutputV1 {
    /**
     * The name identifying the agent where the connector is hosted
     */
    "agentName": string;
    /**
     * The connectionID of the connection. This ID uniquely identifies this connection but it would not necessary be in the form of an UUID
     */
    "connectionId": string;
    /**
     * The message indicating the status of the connection to the datasource (e.g. reason for disconnection)
     */
    "connectionMessage"?: string;
    /**
     * The name of this datasource's connector developer.
     */
    "connectorDeveloperName"?: string;
    /**
     * The support URL of this datasource's connector developer.
     */
    "connectorDeveloperSupportUrl"?: string;
    /**
     * The name identifying the connector
     */
    "connectorName"?: string;
    /**
     * The timestamp of the connection creation
     */
    "createdAt"?: string;
    /**
     * Indicates if the configuration is managed by the datasource and cannot be changed in Seeq
     */
    "datasourceManaged"?: boolean;
    /**
     * The ISO 8601 date of when the connection was disabled (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
     */
    "disabledAt"?: string;
    /**
     * The username of the user who disabled the connection
     */
    "disabledBy"?: string;
    /**
     * Indicates if the connection has an error
     */
    "hasError"?: boolean;
    /**
     * The ISO 8601 date of the last successful connection to the external datasource (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
     */
    "lastSuccessfulConnectedAt"?: string;
    /**
     * The name of this connection. The name should represent the specific data source to which this connection connects.  Example: AMAZONA-4RV912N
     */
    "name": string;
    /**
     * The status of the current connection between the datasource and the connector. Valid values are CONNECTING, CONNECTED, DISCONNECTED and DISABLED. If the state is DISCONNECTED (or CONNECTING), it could be caused by a failure in the connection between the connector and its datasource or a failed connection between the Seeq application server and the agent hosting this connector. See connectionMessage.
     */
    "status"?: string;
    /**
     * The synchronization status of the current connection between the datasource and this connector. Valid values are SYNC_UNKNOWN, SYNC_INITIALIZING, SYNC_IN_PROGRESS, SYNC_ARCHIVING_DELETED_ITEMS, SYNC_COMPLETE, SYNC_SUCCESS, SYNC_FAILED
     */
    "syncStatus"?: SyncStatusEnum;
}

export enum SyncStatusEnum {
    ARCHIVINGDELETEDITEMS = 'SYNC_ARCHIVING_DELETED_ITEMS' as any,
    INPROGRESS = 'SYNC_IN_PROGRESS' as any,
    INITIALIZING = 'SYNC_INITIALIZING' as any,
    SUCCESS = 'SYNC_SUCCESS' as any,
    COMPLETE = 'SYNC_COMPLETE' as any,
    FAILED = 'SYNC_FAILED' as any,
    UNKNOWN = 'SYNC_UNKNOWN' as any
}
